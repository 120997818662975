<template>
  <div ref="el"></div>
</template>

<script>
import { ref, onMounted, computed, defineComponent } from "vue";

function distinct(arr) {
  return Array.from(new Set(arr));
}

const initialEvents = [
  "preload",
  "setup",
  "draw",

  "deviceMoved",
  "deviceTurned",
  "deviceShaken",

  "keyPressed",
  "keyReleased",
  "keyTyped",

  "mouseMoved",
  "mouseDragged",
  "mousePressed",
  "mouseReleased",
  "mouseClicked",
  "doubleClicked",
  "mouseWheel",

  "touchStarted",
  "touchMoved",
  "touchEnded",

  "windowResized",
];

export default defineComponent({
  name: "VueP5",
  props: ["additionalEvents"],
  setup(props, { emit }) {
    const el = ref(null);

    const p5Events = computed(() => {
      const { additionalEvents } = props;
      return Array.isArray(additionalEvents)
        ? distinct(initialEvents.concat(additionalEvents))
        : initialEvents;
    });

    onMounted(async () => {
      const { default: p5 } = await import("p5");

      new p5((sketch) => {
        emit("sketch", sketch);

        for (const p5EventName of p5Events.value) {
          const vueEventName = p5EventName.toLowerCase();
          const savedCallback = sketch[p5EventName];

          sketch[p5EventName] = (...args) => {
            if (savedCallback) {
              savedCallback(sketch, ...args);
            }
            emit(vueEventName, sketch, ...args);
          };
        }
      }, el.value);
    });

    return { el };
  },
});
</script>

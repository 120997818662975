<template>
  <BlueskyWrapper style="font-family: 'Fredoka', sans-serif !important" />
</template>

<script>
import BlueskyWrapper from "./components/BlueskyWrapper.vue";

export default {
  name: "App",
  components: {
    BlueskyWrapper,
  },
};
</script>

<style>
#app {
  font-family: Fredoka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<template>
  <div class="relative">
    <VueP5
      id="p5-canvas"
      @setup="setup"
      @draw="draw"
      @mousemoved="handleMouseMove"
      @mouseclicked="handleMouseClick"
    />
    <div
      v-if="hoveredPostContent"
      :style="tooltipStyles"
      class="absolute bg-gray-800 text-white px-4 py-2 rounded shadow-lg z-10 max-w-lg"
    >
      <div>{{ hoveredPostContent }}</div>
      <div v-if="hoveredPostCreatedAt" class="text-xs pt-2 opacity-90">
        {{ hoveredPostCreatedAt }}
        <span
          ><span class="px-1">·</span> Click
          {{ isMobileComputed ? "this tooltip" : "anywhere" }} to open the
          post</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import VueP5 from "@/components/Processing/VueP5.vue";
import {
  ref,
  defineExpose,
  computed,
  onUnmounted,
  onMounted,
  defineProps,
  watch,
} from "vue";

// Props
const props = defineProps({
  speed: {
    type: String, // "fast" or "slow"
    required: true,
  },
  skipLogic: {
    type: String, // "queue" or "skip"
    required: true,
  },
  paused: {
    type: Boolean, // Play/pause state
    required: true,
  },
  ignoreHover: {
    type: Boolean, // Is dialog open
    required: true,
  },
  filterInput: {
    type: String, // Filter input
    required: true,
  },
  selectedFont: {
    type: String, // Selected font
    required: true,
  },
  fontSize: {
    type: String, // Font size (small, medium, large)
    required: true,
  },
  emojiOnly: {
    type: Boolean, // Show only emojis
    required: true,
  },
});

const MAX_EMOJIS = 500;
const MAX_EMOJIS_MOBILE = 35;

// Constants
const CONFIG = {
  fast: {
    SPEED: 6,
    SPEED_MOBILE: 6,
    EMOJI_SPEED: 9,
    EMOJI_SPEED_MOBILE: 9,
    MAX_WORDS: 2000,
    MAX_WORDS_MOBILE: 400,
    FADE_THRESHOLD: 0.15,
  },
  slow: {
    SPEED: 1.5,
    SPEED_MOBILE: 2,
    EMOJI_SPEED: 3,
    EMOJI_SPEED_MOBILE: 3,
    MAX_WORDS: 2000,
    MAX_WORDS_MOBILE: 400,
    FADE_THRESHOLD: 0.3,
  },
};

const HOVERED_WORD_SIZE = 24;
const WORD_MAX_OPACITY = 110;
const HOVERED_OPACITY = 220;
const NON_HOVERED_OPACITY = 70;
const POST_COOLDOWN = 60;
const POST_COOLDOWN_MOBILE = 120;
const MAX_PENDING_POSTS = 500;
const MAX_WORD_COUNT = 5000;
const EMOJI_SIZE = 48;

// State
const words = ref([]);
const emojiWords = ref([]);
const pendingPosts = ref([]);
const position = ref(0);
const processingPost = ref(null);
const tooltipPosition = ref({ x: 0, y: 0 });
const hoveredPostId = ref(null);
const hoveredPostContent = ref(null);
const hoveredPostCreatedAt = ref(null);
const hoveredPostUri = ref(null);
const hoverLocation = ref([]);
let postCooldown = 0;

const smallWordSize = 13;
const mediumWordSize = 15;
const largeWordSize = 17;

const getWordSize = () => {
  switch (props.fontSize) {
    case "small":
      return smallWordSize;
    case "medium":
      return mediumWordSize;
    case "large":
      return largeWordSize;
    default:
      return mediumWordSize;
  }
};

let wordSize = getWordSize();

const sketchGlobal = ref(null);

const isMobileComputed = computed(() => isMobile());

const handleMouseLeave = () => {
  hoveredPostId.value = null;
  hoveredPostContent.value = null;
  hoveredPostCreatedAt.value = null;
  hoveredPostUri.value = null;
};

onMounted(() => {
  window.addEventListener("mouseleave", handleMouseLeave);
});

onUnmounted(() => {
  window.removeEventListener("mouseleave", handleMouseLeave);
});

// Helper to create word objects
const createWords = (messageData, speed) => {
  const spreadMultiplier = isMobileComputed.value ? 3 : 1;
  const verticalSpread = spreadMultiplier * (isMobileComputed.value ? 30 : 5); // Increase spread for mobile
  const wordsArray = props.emojiOnly
    ? extractEmojis(messageData.text) // Only emojis in emoji mode
    : messageData.text.split(/\s+/); // Full text otherwise

  return wordsArray.map((word) => {
    const randomizedSpeed = speed * (0.9 + Math.random() * 0.2);
    return {
      word,
      x: Math.random() * window.innerWidth,
      y: -wordSize * (Math.random() * verticalSpread), // Adjust spread
      speed: randomizedSpeed,
      opacity: isMobileComputed.value ? 255 : WORD_MAX_OPACITY,
      postId: messageData.postId,
      content: messageData.text,
      createdAt: messageData.createdAt,
      uri: messageData.uri,
      did: messageData.did,
      rkey: messageData.rkey,
    };
  });
};

const isAtCapacity = () => {
  const maxWords = getMaxWords();
  const maxEmojis = isMobileComputed.value ? MAX_EMOJIS_MOBILE : MAX_EMOJIS;
  const maxWordsCheck = props.emojiOnly ? maxEmojis : maxWords;
  const arrToCheck = props.emojiOnly ? emojiWords.value : words.value;
  return arrToCheck.length >= maxWordsCheck;
};

// Add a new event
const addEvent = (event) => {
  if (props.paused) return;

  if (isAtCapacity()) return;

  const text = event.commit?.record?.text;
  const createdAt = event.commit?.record?.createdAt;
  const uri = event.commit?.record?.reply?.parent?.uri;
  const did = event.did;
  const rkey = event.commit?.rkey;

  if (!text || !createdAt || !uri || !did || !rkey) return;

  const messageData = {
    text,
    postId: event.commit?.rev || Date.now().toString(),
    createdAt,
    uri,
    did,
    rkey,
  };

  // Extract emojis if `emojiOnly` is enabled
  if (props.emojiOnly) {
    const emojis = extractEmojis(text);
    if (emojis.length > 0) {
      const emojiObjects = emojis.map(
        (emoji) => wordObjTemplateForEmoji(emoji, text, did, rkey) // Pass original text as content
      );
      emojiWords.value.push(...emojiObjects);
    }
    return; // Skip adding to `words` in emoji-only mode
  }

  // Process normally if not in emoji-only mode
  if (props.skipLogic === "skip" && !processingPost.value) {
    processingPost.value = messageData;
  } else if (props.skipLogic === "queue") {
    if (pendingPosts.value.length >= MAX_PENDING_POSTS) {
      pendingPosts.value.shift(); // Remove the oldest post
    }
    pendingPosts.value.push(messageData);
  }
};

const processPendingPosts = (config) => {
  if (props.paused) return;

  const maxWords = getMaxWords();
  const speed = isMobileComputed.value ? config.SPEED_MOBILE : config.SPEED;
  const cooldown = isMobileComputed.value
    ? POST_COOLDOWN_MOBILE
    : POST_COOLDOWN;

  if (props.skipLogic === "skip") {
    if (
      !processingPost.value &&
      pendingPosts.value.length > 0 &&
      postCooldown <= 0
    ) {
      processingPost.value = pendingPosts.value.shift();
      postCooldown = cooldown;
    }
    if (processingPost.value) {
      words.value.push(...createWords(processingPost.value, speed));
      processingPost.value = null;
    }
  } else if (props.skipLogic === "queue") {
    if (
      pendingPosts.value.length > 0 &&
      words.value.length < maxWords &&
      postCooldown <= 0
    ) {
      const nextPost = pendingPosts.value.shift();
      words.value.push(...createWords(nextPost, speed));
      postCooldown = cooldown;
    }
  }

  if (postCooldown > 0) postCooldown--;
};

const getTimeAgoString = (timestamp) => {
  const now = new Date();
  const diff = Math.max(0, Math.floor((now - new Date(timestamp)) / 1000)); // Difference in seconds

  if (diff < 60) {
    return `Posted ${diff} second${diff > 1 ? "s" : ""} ago`;
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `Posted ${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `Posted ${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else {
    const days = Math.floor(diff / 86400);
    return `Posted ${days} day${days > 1 ? "s" : ""} ago`;
  }
};

const wordWidthCache = new Map();

const getTextWidth = (sketch, word) => {
  if (wordWidthCache.has(word)) {
    return wordWidthCache.get(word);
  }
  const width = sketch.textWidth(word);
  wordWidthCache.set(word, width);
  return width;
};

// Mouse hover handling
const handleMouseMove = (event) => {
  if (props.ignoreHover) {
    return;
  }

  const { mouseX, mouseY } = event;
  tooltipPosition.value = { x: mouseX, y: mouseY };

  // Check if we are on mobile
  if (isMobileComputed.value && hoveredPostContent.value) {
    const tooltipElement = document.querySelector(".absolute.bg-gray-800");
    if (tooltipElement) {
      const rect = tooltipElement.getBoundingClientRect();
      const isHoveringTooltip =
        mouseX >= rect.left &&
        mouseX <= rect.right &&
        mouseY >= rect.top &&
        mouseY <= rect.bottom;

      if (isHoveringTooltip) {
        window.open(hoveredPostUri.value, "_blank");
        return;
      }
    }
  }

  if (
    mouseY <= 5 || // Mouse is above the canvas
    mouseY >= window.innerHeight - 50 || // Mouse is below the canvas (within 50px from the bottom)
    mouseX <= 5 || // Mouse is to the left of the canvas
    mouseX >= window.innerWidth - 5 // Mouse is to the right of the canvas
  ) {
    hoveredPostId.value = null;
    hoveredPostContent.value = null;
    hoveredPostCreatedAt.value = null;
    hoveredPostUri.value = null;
    return;
  }

  // Check if mouse is hovering over a word
  const arrToCheck = props.emojiOnly ? emojiWords.value : words.value;

  const hoveredWord = arrToCheck.find((word) => {
    const sz = props.emojiOnly ? EMOJI_SIZE : wordSize;
    const halfTextWidth = getTextWidth(sketchGlobal.value, word.word) / 2 || 0;

    return (
      mouseX > word.x - halfTextWidth &&
      mouseX < word.x + halfTextWidth &&
      mouseY > word.y - sz / 2 &&
      mouseY < word.y + sz / 2 &&
      (!props.filterInput ||
        word.word.toLowerCase().includes(props.filterInput.toLowerCase()))
    );
  });

  if (hoveredWord) {
    hoveredPostId.value = hoveredWord.postId;
    hoveredPostContent.value = hoveredWord.content;
    hoveredPostCreatedAt.value = getTimeAgoString(hoveredWord.createdAt);
    hoveredPostUri.value = `https://bsky.app/profile/${hoveredWord.did}/post/${hoveredWord.rkey}`;
    hoverLocation.value = [mouseX, mouseY];
  } else {
    // Check distance from the last hover location
    const [lastX, lastY] = hoverLocation.value || [];
    const distance = Math.sqrt(
      Math.pow(mouseX - (lastX || 0), 2) + Math.pow(mouseY - (lastY || 0), 2)
    );

    if (distance > 20) {
      hoveredPostId.value = null;
      hoveredPostContent.value = null;
      hoveredPostCreatedAt.value = null;
      hoveredPostUri.value = null;
    }
  }
};

const handleMouseClick = () => {
  if (props.ignoreHover) {
    return;
  }

  if (isMobileComputed.value) {
    return;
  }

  if (hoveredPostId.value) {
    window.open(hoveredPostUri.value, "_blank");
  }
};

const tooltipStyles = computed(() => {
  const tooltipWidth = 300; // Estimated max width of the tooltip
  const tooltipHeight = 150; // Estimated max height of the tooltip
  const edgeBuffer = 20; // Space to leave near edges

  // Adjust horizontal position
  const xOffset =
    tooltipPosition.value.x + tooltipWidth + edgeBuffer > window.innerWidth
      ? tooltipPosition.value.x - tooltipWidth - edgeBuffer // Show on the left
      : tooltipPosition.value.x + edgeBuffer; // Show on the right

  // Adjust vertical position
  const yOffset =
    tooltipPosition.value.y + tooltipHeight + edgeBuffer > window.innerHeight
      ? tooltipPosition.value.y - tooltipHeight - edgeBuffer // Show above
      : tooltipPosition.value.y + edgeBuffer; // Show below

  return {
    top: `${Math.max(yOffset, edgeBuffer)}px`, // Ensure it doesn't go off-screen
    left: `${Math.max(xOffset, edgeBuffer)}px`, // Ensure it doesn't go off-screen
    // fontFamily: props.selectedFont,
  };
});

// P5 setup
const setup = (sketch) => {
  sketch.createCanvas(window.innerWidth, window.innerHeight);

  // sketch.frameRate(45);
  sketch.windowResized = () => {
    sketch.resizeCanvas(window.innerWidth, window.innerHeight);
  };

  sketchGlobal.value = sketch;
};

// Cleanup
onUnmounted(() => {});

// Draw background sky
const drawSky = (sketch) => {
  const gradient = sketch.drawingContext.createLinearGradient(
    0,
    0,
    0,
    sketch.height
  );

  gradient.addColorStop(0, "rgb(10, 50, 150)");
  gradient.addColorStop(0.4, "rgb(40, 100, 200)");
  gradient.addColorStop(0.8, "rgb(80, 140, 220)");
  gradient.addColorStop(1, "rgb(150, 190, 240)");

  sketch.drawingContext.fillStyle = gradient;
  sketch.drawingContext.fillRect(0, 0, sketch.width, sketch.height);
};

const drawMountains = (sketch) => {
  const MOUNTAIN_COLORS = [
    "rgba(180, 190, 205, 0.02)",
    "rgba(170, 180, 195, 0.04)",
    "rgba(160, 170, 185, 0.06)",
    "rgba(150, 160, 175, 0.08)",
    "rgba(140, 150, 165, 0.1)",
    "rgba(130, 140, 155, 0.12)",
    "rgba(120, 130, 145, 0.14)",
    "rgba(110, 120, 135, 0.16)",
    "rgba(100, 110, 125, 0.18)",
  ];
  const NOISE_SCALE = 0.001;
  const MOUNTAIN_HEIGHT = 0.33;

  for (let i = 0; i < MOUNTAIN_COLORS.length; i++) {
    const noiseOffset = i * 1000;
    const maxHeight = sketch.height * MOUNTAIN_HEIGHT * (1 - i * 0.01);

    sketch.fill(MOUNTAIN_COLORS[i]);
    sketch.noStroke();

    sketch.beginShape();
    sketch.vertex(-50, sketch.height);

    const currentMountainPoints = [];

    for (let x = -50; x <= sketch.width + 50; x += 5) {
      const noiseVal = sketch.noise(
        (x + position.value * (0.3 - i * 0.03)) * NOISE_SCALE + noiseOffset
      );

      const y = sketch.height - maxHeight * noiseVal;

      sketch.vertex(x, y);
      currentMountainPoints.push(y);
    }

    sketch.vertex(sketch.width + 50, sketch.height);
    sketch.endShape(sketch.CLOSE);
  }

  position.value += 0.5;
};

// Draw words
const drawWords = (sketch) => {
  const activeWords = props.emojiOnly ? emojiWords.value : words.value; // Use emojiWords if emojiOnly mode is enabled
  const updatedWords = []; // To hold emojis/words that are still on screen

  // Precompute constants to avoid repeated calculations
  const isMobile = isMobileComputed.value;
  const textSize = props.emojiOnly ? EMOJI_SIZE : wordSize;
  const maxEmojis = isMobile ? MAX_EMOJIS_MOBILE : MAX_EMOJIS;
  const maxOpacity = WORD_MAX_OPACITY;
  const hoverOpacity = HOVERED_OPACITY;
  const nonHoverOpacity = NON_HOVERED_OPACITY;
  const hoveredSize = props.emojiOnly ? EMOJI_SIZE + 24 : HOVERED_WORD_SIZE;
  const isAnyHovered = hoveredPostId.value !== null;

  sketch.textAlign(sketch.CENTER, sketch.CENTER); // Align text once globally

  for (let i = 0; i < activeWords.length; i++) {
    const word = activeWords[i];

    // Move word downward if not paused
    if (!props.paused) word.y += word.speed;

    const isHovered = word.postId === hoveredPostId.value;

    // Set size and color based on hover state
    sketch.textSize(isHovered ? hoveredSize : textSize);

    const color = isHovered
      ? [255, 255, 255, hoverOpacity]
      : isAnyHovered
      ? [255, 255, 255, nonHoverOpacity]
      : [255, 255, 255, maxOpacity];

    // Apply fill color with opacity
    sketch.fill(color[0], color[1], color[2], color[3]);

    sketch.noStroke();

    // Render the word only if it matches the filter
    if (
      props.filterInput.length === 0 ||
      word.word.toLowerCase().includes(props.filterInput.toLowerCase())
    ) {
      sketch.text(word.word, word.x, word.y);
    }

    // Keep words that are not fully off the bottom of the canvas
    if (word.y - textSize / 2 <= sketch.height) {
      updatedWords.push(word);
    }
  }

  // Limit the number of rendered words/emojis to the max allowed
  if (sketch.frameCount % 10 === 0) {
    if (props.emojiOnly) {
      emojiWords.value = updatedWords.slice(-maxEmojis);
    } else {
      words.value = updatedWords.slice(-MAX_WORD_COUNT);
    }
  }
  // Clear hover state if the hovered word is no longer active
  if (
    hoveredPostId.value !== null &&
    !updatedWords.some((word) => word.postId === hoveredPostId.value)
  ) {
    hoveredPostId.value = null;
    hoveredPostContent.value = null;
    hoveredPostCreatedAt.value = null;
    hoveredPostUri.value = null;
  }
};

// Main draw function
const draw = (sketch) => {
  const config = CONFIG[props.speed];
  drawSky(sketch);
  drawMountains(sketch);
  drawWords(sketch, config);

  if (!isAtCapacity()) processPendingPosts(config);
};

defineExpose({ addEvent });

const isMobile = () => {
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(
    navigator.userAgent
  );
};

const getMaxWords = () => {
  const config = CONFIG[props.speed];
  return isMobileComputed.value ? config.MAX_WORDS_MOBILE : config.MAX_WORDS;
};

watch(
  () => props.selectedFont,
  (newValue) => {
    if (sketchGlobal.value) {
      sketchGlobal.value.textFont(newValue);
    }
  }
);

watch(
  () => props.fontSize,
  () => {
    wordSize = getWordSize();
  }
);

watch(
  () => props.speed,
  (newValue) => {
    const config = CONFIG[newValue];
    const speed = isMobileComputed.value ? config.SPEED_MOBILE : config.SPEED;

    words.value = words.value.map((word) => ({
      ...word,
      speed: speed * (0.9 + Math.random() * 0.2),
    }));

    emojiWords.value = emojiWords.value.map((word) => ({
      ...word,
      speed: config.EMOJI_SPEED * (0.9 + Math.random() * 0.2),
    }));
  }
);

watch(
  () => props.emojiOnly,
  (newValue) => {
    if (newValue) {
      sketchGlobal.value.textFont("Apple Color Emoji");
    } else {
      sketchGlobal.value.textFont(props.selectedFont);
    }

    if (newValue) {
      // Recreate `emojiWords` using only emojis with full metadata
      emojiWords.value = words.value.flatMap((wordObj) => {
        const emojis = extractEmojis(wordObj.word); // Extract emojis from the word
        return emojis.map((emoji) => ({
          ...wordObjTemplateForEmoji(emoji, wordObj.content), // Pass original content
          postId: wordObj.postId,
          createdAt: wordObj.createdAt,
          uri: wordObj.uri,
          did: wordObj.did,
          rkey: wordObj.rkey,
          // Randomize positions
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight, // Random y position
        }));
      });
    } else {
      // Reset words and randomize their positions
      words.value = words.value.map((word) => ({
        ...word,
        x: Math.random() * window.innerWidth, // Random x position
        y: Math.random() * window.innerHeight, // Random y position
      }));
      emojiWords.value = [];
    }
  }
);

const wordObjTemplateForEmoji = (word, content = "", did = "", rkey = "") => {
  const emojiSpeedKey = isMobileComputed.value
    ? "EMOJI_SPEED_MOBILE"
    : "EMOJI_SPEED";
  const randomizedSpeed =
    CONFIG[props.speed][emojiSpeedKey] * (0.9 + Math.random() * 0.2);
  return {
    word,
    x: Math.random() * window.innerWidth,
    y: -wordSize * (Math.random() * 30),
    speed: randomizedSpeed,
    opacity: isMobileComputed.value ? 255 : WORD_MAX_OPACITY,
    postId: Date.now().toString(),
    content, // Populate with provided content
    createdAt: new Date().toISOString(),
    uri: "",
    did,
    rkey,
  };
};

// const normalizeText = (text) => {
//   return text.normalize("NFC");
// };

const extractEmojis = (text) => {
  const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u{1F1E0}-\u{1F1FF}]+/gu;
  return text.match(emojiRegex) || [];
};
</script>

<style>
#p5-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

div[absolute] {
  pointer-events: none;
}
</style>
